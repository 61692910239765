/* body{
  font-family: 'Sahel', sans-serif;
} */

canvas {
  outline: none;
}
/* 
input{
  font-family: 'Roboto', sans-serif;
} */
/* 
* {
  font-family: Sahel, sans-serif;
} */

textarea {
  font-family: 'Roboto', sans-serif;
}
